import * as React from "react";
import { Field, WrappedFieldProps } from "redux-form";
import allowSvg from "src/images/allow.svg";
import errorSvg from "src/images/error.svg";
import styles from "./TextInput.module.css";
// tslint:disable: react-a11y-required

interface Props {
  name: string;
  type?: string;
  label: string;
  black?: boolean;
  required?: boolean;
  validate?(string: string): string;
}

const TextInput: React.FC<Props & WrappedFieldProps> = (
  props: Props & WrappedFieldProps
) => {
  return (
    <div
      className={`${styles.inputWrapper} ${props.black ? styles.black : ""}`}
    >
      <input
        {...props.input}
        type={props.type || "text"}
        placeholder={`${props.label} ${props.required ? "*" : ""}`}
        className={`${styles.input} ${props.black ? styles.black : ""}`}
        required={props.required}
        aria-label={props.label}
        aria-required={props.required ? "true" : "false"}
      />
      <div className={styles.validationSigns}>
        {(props.meta.submitFailed || props.meta.invalid) && props.meta.error && (
          // tslint:disable-next-line
          <img src={errorSvg} alt="error"></img>
        )}
        {props.meta.touched && props.meta.valid && props.input?.value && (
          // tslint:disable-next-line
          <img src={allowSvg} alt="OK"></img>
        )}
      </div>
    </div>
  );
};

const TextField = (props: Props) => (
  <Field
    name={props.name}
    props={props}
    component={TextInput}
    validate={props.validate}
  />
);

export default TextField;
